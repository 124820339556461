import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./RequestBookingIndex.css";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  Media,
  Button,
} from "react-bootstrap";
import {
  fetchBookingsStart,
  bookingCancelStart,
} from "../../store/actions/BookingAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import RequestListLoader from "../Handlers/Loaders/RequestListLoader";
import { t, withTranslation } from "react-multi-lang";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const RequestList = (props) => {
  useEffect(() => {
    props.dispatch(fetchBookingsStart());
  }, []);

  const handleCancel = (event, booking_id) => {
    event.preventDefault();
    console.log(booking_id);
    props.dispatch(
      bookingCancelStart({
        booking_id: booking_id,
      })
    );
  };

  const cancelConfirmation = (event, booking_id) => {
    confirmAlert({
      message: t("do_you_want_to_cancel_booking"),
      buttons: [
        {
          label: t("yes"),
          onClick: () => handleCancel(event, booking_id)
        },
        {
          label: t("no"),
        }
      ]
    });
  };


  return (
    <>
      <div className="main-wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="heading-title">{t("request_list")}</h3>
              <div className="subscription-list-card">
                {props.bookings.loading ? (
                  <RequestListLoader />
                ) : props.bookings.data.bookings.length > 0 ? (
                  <Table className="subscription-list-table">
                    <thead>
                      <tr>
                        <th>{t("model")}</th>
                        <th>{t("to")}</th>
                        <th>{t("from")}</th>
                        {/* <th>{t("booking_id")}</th> */}
                        <th>{t("request_id")}</th>
                        <th>{t("occasion")}</th>
                        {/* <th>Pronoun</th> */}
                        <th>{t("status")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.bookings.data.bookings.map((data) => (
                        <tr>
                          <td>
                            <Link to={"/" + data.model.username}>
                              {data.model.name}
                            </Link>
                          </td>
                          <td>{data.to_username}</td>
                          <td>{data.username}</td>
                          <td>
                            <Link
                              to={`/request-view/` + data.booking_unique_id}
                            >
                              {data.booking_unique_id}
                            </Link>
                            <p className="mt-2">
                              <b>Booked At:</b> {data.booked_at}
                            </p>
                          </td>
                          <td>{data.occasion_name}</td>
                          {/* <td>{data.pronoun}</td> */}
                          <td>{data.booking_status}</td>
                          <td>
                            {data.booking_button_status.file_upload_status ==
                              1 &&
                            localStorage.getItem("is_model") == 2 &&
                            data.model.user_unique_id ==
                              localStorage.getItem("user_unique_id") ? (
                              <Link to={`/booking/` + data.booking_unique_id}>
                                <Button
                                  type="button"
                                  className="btn-request-view"
                                >
                                  File Upload
                                </Button>
                              </Link>
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/request-view/` + data.booking_unique_id}
                            >
                              <Button
                                type="button"
                                className="btn-request-view"
                              >
                                View
                              </Button>
                            </Link>
                            {data.booking_button_status.cancel_btn_status ==
                            1 ? (
                              <Link to="#">
                                <Button
                                  type="button"
                                  className="btn-request-view"
                                  // onClick={(event) =>
                                  //   handleCancel(event, data.booking_id)
                                  // }
                                  onClick={(event) =>
                                    cancelConfirmation(event, data.booking_id)}
                                >
                                  Cancel
                                </Button>
                              </Link>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <NoDataFound></NoDataFound>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bookings: state.bookings.bookings,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(RequestList));
