import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
 Container,
 Row,
 Col,
 Image, 
 Media,
 Nav,
 Tab,
 Table,
 Button
} from "react-bootstrap";
import { connect } from "react-redux";

const MyTicketsIndex = (props) => {

    return (
    <>
        <div className="events-wrapper-sec">
            <div className="events-sidebar-sec">
                <ul className="events-list-sec list-unstyled">
                    <Media as="li">
                        <Link to="#" className="active">
                            <Image
                                src="assets/images/search-white-icon.svg"
                                alt="user-image"
                                className="search-siderbar-icon"
                            /> 
                            Explore events
                        </Link>
                    </Media>
                    <Media as="li">
                        <Link to="#">
                            <Image
                                src="assets/images/live-tickets-icon.svg"
                                alt="user-image"
                                className="search-siderbar-icon"
                            /> 
                            My tickets
                        </Link>
                    </Media>
                </ul>
            </div>
            <div className="events-right-main-content">
                <div className="live-events-header-sec">
                    <h2>My live video calls</h2>
                    <div className="my-live-video-call-sec">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming">
                            <Row>
                                <Col sm={12}>
                                <Nav variant="pills">
                                    <Nav.Item>
                                    <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="completed">Completed</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="declined">Declined</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="upcoming">
                                        <div className="custom-table-list">
                                            <div className="custom-table-header">
                                                <div className="custom-table-header-card">
                                                    <h5>Event Name</h5>
                                                    <h5>Date</h5>
                                                    <h5>Picture</h5>
                                                    <h5>Ticekt Bookings</h5>
                                                    <h5>Action</h5>
                                                </div>
                                            </div>
                                            <div className="custom-table-body">
                                                <div className="custom-table-card">
                                                    <p>New Year Fiesta - The Vibrant Side Of The End</p>
                                                    <p>22/05/2022</p>
                                                    <p>
                                                        <Image
                                                        src={
                                                            window.location.origin + "/assets/images/live-events/event-list-1.png"
                                                        }
                                                            alt="live-events-img"
                                                            className="live-events-list-img"
                                                        />
                                                    </p>
                                                    <p>25</p>
                                                    <p>
                                                        <Button className="cancel-btn">Cancel</Button>
                                                    </p>
                                                </div>
                                                <div className="custom-table-card">
                                                    <p>New Year Fiesta - The Vibrant Side Of The End</p>
                                                    <p>22/05/2022</p>
                                                    <p>
                                                        <Image
                                                        src={
                                                            window.location.origin + "/assets/images/live-events/event-list-1.png"
                                                        }
                                                            alt="live-events-img"
                                                            className="live-events-list-img"
                                                        />
                                                    </p>
                                                    <p>25</p>
                                                    <p>
                                                        <Button className="cancel-btn">Cancel</Button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="completed">
                                        <div className="custom-table-list">
                                            <div className="custom-table-header">
                                                <div className="custom-table-header-card">
                                                    <h5>Event Name</h5>
                                                    <h5>Date</h5>
                                                    <h5>Picture</h5>
                                                    <h5>Ticekt Bookings</h5>
                                                    <h5>Status</h5>
                                                </div>
                                            </div>
                                            <div className="custom-table-body">
                                                <div className="custom-table-card">
                                                    <p>New Year Fiesta - The Vibrant Side Of The End</p>
                                                    <p>22/05/2022</p>
                                                    <p>
                                                        <Image
                                                        src={
                                                            window.location.origin + "/assets/images/live-events/event-list-1.png"
                                                        }
                                                            alt="live-events-img"
                                                            className="live-events-list-img"
                                                        />
                                                    </p>
                                                    <p>25</p>
                                                    <p>
                                                        <Button className="completed-btn">Completed</Button>
                                                    </p>
                                                </div>
                                                <div className="custom-table-card">
                                                    <p>New Year Fiesta - The Vibrant Side Of The End</p>
                                                    <p>22/05/2022</p>
                                                    <p>
                                                        <Image
                                                        src={
                                                            window.location.origin + "/assets/images/live-events/event-list-1.png"
                                                        }
                                                            alt="live-events-img"
                                                            className="live-events-list-img"
                                                        />
                                                    </p>
                                                    <p>25</p>
                                                    <p>
                                                        <Button className="completed-btn">Completed</Button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="declined">
                                        <div className="custom-table-list">
                                            <div className="custom-table-header">
                                                <div className="custom-table-header-card">
                                                    <h5>Event Name</h5>
                                                    <h5>Date</h5>
                                                    <h5>Picture</h5>
                                                    <h5>Ticekt Bookings</h5>
                                                    <h5>Status</h5>
                                                </div>
                                            </div>
                                            <div className="custom-table-body">
                                                <div className="custom-table-card">
                                                    <p>New Year Fiesta - The Vibrant Side Of The End</p>
                                                    <p>22/05/2022</p>
                                                    <p>
                                                        <Image
                                                        src={
                                                            window.location.origin + "/assets/images/live-events/event-list-1.png"
                                                        }
                                                            alt="live-events-img"
                                                            className="live-events-list-img"
                                                        />
                                                    </p>
                                                    <p>25</p>
                                                    <p>
                                                        <Button className="declined-btn">Declined</Button>
                                                    </p>
                                                </div>
                                                <div className="custom-table-card">
                                                    <p>New Year Fiesta - The Vibrant Side Of The End</p>
                                                    <p>22/05/2022</p>
                                                    <p>
                                                        <Image
                                                        src={
                                                            window.location.origin + "/assets/images/live-events/event-list-1.png"
                                                        }
                                                            alt="live-events-img"
                                                            className="live-events-list-img"
                                                        />
                                                    </p>
                                                    <p>25</p>
                                                    <p>
                                                        <Button className="declined-btn">Declined</Button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(MyTicketsIndex);
