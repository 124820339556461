import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import { forgotPasswordStart } from "../../store/actions/UserAction";
import { t, withTranslation } from "react-multi-lang";

const $ = window.$;

const ForgotPasswordIndex = (props) => {
  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

  const handleForgotPassword = (event) => {
    event.preventDefault();
    props.dispatch(forgotPasswordStart(forgotPasswordInputData));
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 6, offset: 3 }}
              >
                <div className="login-box">
                  <h2>{t("forgot_password")}</h2>
                  <Form
                    className="login-form mt-4"
                    onSubmit={handleForgotPassword}
                    method="post"
                  >
                    <Form.Group controlId="formBasicEmail" className="mb-4">
                      <Form.Label>{t("email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("you@email.com")}
                        value={forgotPasswordInputData.email}
                        name="email"
                        onChange={(event) =>
                          setForgotPasswordInputData({
                            ...forgotPasswordInputData,
                            email: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    {/* <Button
                      className="btn-reset"
                      onClick={handleForgotPassword}
                      className="btn btn-auth btn-lg"
                      disabled={props.forgotPassword.buttonDisable}
                    >
                      {props.forgotPassword.loadingButtonContent !== null
                        ? props.forgotPassword.loadingButtonContent
                        : "Forgot Password"}
                    </Button> */}

                    <Button
                      className="btn-email"
                      onClick={handleForgotPassword}
                      disabled={props.forgotPassword.buttonDisable}
                    >
                      {props.forgotPassword.loadingButtonContent !== null
                        ? props.forgotPassword.loadingButtonContent
                        : t("forgot_password")}
                    </Button>
                  </Form>
                  <div className="login-footer-sub-sec-1">
                    <span className="login-desc-text-1">
                      <Link to="/login">{t("already_have_an_account")}</Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(ForgotPasswordIndex));
