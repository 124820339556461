import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Subscription.css";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Dropdown, Media, Button } from "react-bootstrap";
import configuration from "react-global-configuration";

const SubscriptionView = (props) => {
    useEffect(() => { }, []);
    return (
        <>
            <div className="main-wrapper subscription-view">
                <Container>
                    <h3 className="heading-title">Subscription View</h3>
                    <div className="subscription-list-my-profile">
                        <Table className="my-profile-table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Duration</th>
                                    <th>Price</th>
                                    <th>No of Subscriber</th>
                                    <th>Total Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Beno Darry</td>
                                    <td>10 Mins</td>
                                    <td>{configuration.get("configData.currency")}15</td>
                                    <td>2</td>
                                    <td>{configuration.get("configData.currency")}10</td>
                                </tr>
                                <tr>
                                    <td>Jerif Daniel</td>
                                    <td>20 Mins</td>
                                    <td>{configuration.get("configData.currency")}15</td>
                                    <td>5</td>
                                    <td>{configuration.get("configData.currency")}40</td>
                                </tr>
                                <tr>
                                    <td>Suderson</td>
                                    <td>15 Mins</td>
                                    <td>{configuration.get("configData.currency")}15</td>
                                    <td>10</td>
                                    <td>{configuration.get("configData.currency")}100</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <Row>
                        <Col md={12}>
                            <div className="subscription-list-card">
                                <Table className="subscription-list-table">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Duration</th>
                                            <th>Price</th>
                                            <th>No of Subscribers</th>
                                            <th>Total Earning</th>
                                            <th>Booking List</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Beno Darry</td>
                                            <td>10 Mins</td>
                                            <td>{configuration.get("configData.currency")}1</td>
                                            <td>12 Subcribers</td>
                                            <td>{configuration.get("configData.currency")}10</td>
                                            <td>
                                                <Dropdown className="subscription-list-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <ul className="list-unstyled subscription-list-dropdown-item">
                                                            <Media as="li">
                                                                <Link to="#">View</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">Edit</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">Delete</Link>
                                                            </Media>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Beno Darry</td>
                                            <td>10 Mins</td>
                                            <td>{configuration.get("configData.currency")}1</td>
                                            <td>12 Subcribers</td>
                                            <td>{configuration.get("configData.currency")}10</td>
                                            <td>
                                                <Dropdown className="subscription-list-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <ul className="list-unstyled subscription-list-dropdown-item">
                                                            <Media as="li">
                                                                <Link to="#">View</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">Edit</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">Delete</Link>
                                                            </Media>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Beno Darry</td>
                                            <td>10 Mins</td>
                                            <td>{configuration.get("configData.currency")}1</td>
                                            <td>12 Subcribers</td>
                                            <td>{configuration.get("configData.currency")}10</td>
                                            <td>
                                                <Dropdown className="subscription-list-dropdown">
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <ul className="list-unstyled subscription-list-dropdown-item">
                                                            <Media as="li">
                                                                <Link to="#">View</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">Edit</Link>
                                                            </Media>
                                                            <Media as="li">
                                                                <Link to="#">Delete</Link>
                                                            </Media>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col md={12}>
                                        <div className="btn-view-more-sec">
                                            <Button className="btn-view-more">View More<i class="fas fa-chevron-right ml-2"></i></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubscriptionView);
