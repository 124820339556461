import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";


import "./Account.css";
import { changePasswordStart } from "../../store/actions/UserAction";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const ChangePassword = (props) => {
  const [inputData, setInputData] = useState({});

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(changePasswordStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="change-password-sec">
          <div className="change-password-card">
            <h3 className="title">{t("change_password")}</h3>
            <Form className="change-password-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("old_passwords")}</Form.Label>
                <Form.Control
                   type={passwordShown ? "text" : "password"}
                  placeholder={t("enter_your_old_password")}
                  name="old_password"
                  value={inputData.old_password}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      old_password: event.currentTarget.value,
                    })
                  }
                />
                 <i
                        className="change-pwd-toggle-password"
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown?eyeSlash:eye}
                      </i>{" "}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("new_passwords")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("enter_your_new_password")}
                  name="password"
                  value={inputData.password}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      password: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("confirm_passwords")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("confirm_password")}
                  name="password_confirmation"
                  value={inputData.password_confirmation}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      password_confirmation: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Button
                className="btn-change-password"
                disabled={props.changePassword.buttonDisable}
                onClick={handleSubmit}
              >
                {props.changePassword.loadingButtonContent != null
                  ? props.changePassword.loadingButtonContent
                  : "Change Password"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(ChangePassword));
