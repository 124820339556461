import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Media,
  Button,
  DropdownButton,
  Dropdown,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";

const EventsRequest = (props) => {
  const defaultValue = {
    year: 2022,
    month: 6,
    day: 7,
  };

  const [selectedDay, setSelectedDay] = useState(defaultValue);

  return (
    <>
      <div className="events-request-sec">
        <Container>
          <Row>
            <Col md={5}>
              <div className="events-request-box">
                <div className="events-details-card">
                  <div className="events-request-user-img-sec">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/single-model-profile-img.jpg"
                      }
                      alt="user-image"
                      className="event-request-user-img"
                    />
                  </div>
                  <div className="events-request-user-info">
                    <h6>Cameo Live video call with</h6>
                    <h4>Shoenice</h4>
                  </div>
                  <div className="events-request-user-list-sec">
                    <ul className="list-unstyled">
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <polyline points="12 6 12 12 16 14"></polyline>
                        </svg>
                        <span>10 minutes</span>
                      </Media>
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polygon points="23 7 16 12 23 17 23 7"></polygon>
                          <rect
                            x="1"
                            y="5"
                            width="15"
                            height="14"
                            rx="2"
                            ry="2"
                          ></rect>
                        </svg>
                        <span>
                          Cameo Live details provided upon confirmation
                        </span>
                      </Media>
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span>Up to 10 people</span>
                      </Media>
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FFFFFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <rect
                            x="1"
                            y="4"
                            width="22"
                            height="16"
                            rx="2"
                            ry="2"
                          ></rect>
                          <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>
                        <span>₹2,327</span>
                      </Media>
                    </ul>
                  </div>
                  <div className="after-requesting-notes">
                    <h5>
                      After your request is accepted you’ll receive an email
                      with your:
                    </h5>
                    <ul className="list-unstyled">
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#00ab55"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        <span>Selected date and time</span>
                      </Media>
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#00ab55"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        <span>Receipt of your purchase</span>
                      </Media>
                      <Media as="li">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#00ab55"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        <span>Link to the Cameo Live call</span>
                      </Media>
                    </ul>
                    <p>
                      Want more info? Check out our{" "}
                      <Link to="#">FAQ page.</Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="events-request-box">
                <div className="event-request-select-date-time">
                  <h4>Select a date and time</h4>
                  <p>
                    Choose up to 3 dates and times (more choices are better in
                    order to find a time that works for you and Shoenice).
                    Availability is shown within a two week window. Check back
                    daily for more availability.
                  </p>
                </div>
                <div className="date-time-picker-card">
                  <div className="date-picker">
                    <Calendar
                      value={selectedDay}
                      onChange={setSelectedDay}
                      shouldHighlightWeekends
                    />
                  </div>
                  <div className="time-picker">
                    <div className="date-picker-selected-card">
                      <h6>Friday, June 10th</h6>
                      <p>All times in </p>
                    </div>
                    <div className="time-picker-box">
                      <div className="time-picker-card">
                        <Button className="time-btn">12:30 am</Button>
                        <Button className="time-btn">1:00 am</Button>
                        <Button className="time-btn">1:30 am</Button>
                        <Button className="time-btn">2:00 am</Button>
                        <Button className="time-btn">2:30 am</Button>
                        <Button className="time-btn">3:00 am</Button>
                        <Button className="time-btn">3:30 am</Button>
                        <Button className="time-btn">4:00 am</Button>
                        <Button className="time-btn">4:30 am</Button>
                        <Button className="time-btn">5:00 am</Button>
                        <Button className="time-btn">5:30 am</Button>
                        <Button className="time-btn">6:00 am</Button>
                        <Button className="time-btn">6:30 am</Button>
                        <Button className="time-btn">7:00 am</Button>
                        <Button className="time-btn">7:30 am</Button>
                        <Button className="time-btn">8:00 am</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-selection-sec mt-5">
                  <div class="event-request-select-date-time">
                    <h4>How many participants?</h4>
                    <p>You can have a max of 10 participants</p>
                  </div>
                  <div className="event-selection-dropdown">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select an option"
                    >
                      <Dropdown.Item href="#/action-1">
                        Small group (1-5)
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Large group (5-10)
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Unsure</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="selection-text-area">
                    <div class="event-request-select-date-time mt-4">
                      <h4 className="m-0">What do you want to talk about?</h4>
                      <p>Shoenice will see this</p>
                    </div>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="My friends and I have been fans of you for years! We just want to see what you're working on and share some of the impacts you've had on our lives"
                        />
                      </Form.Group>

                      <div className="selection-payment-btn">
                        <Button>Continue to Payment</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(EventsRequest);
