import React, { useState, useEffect, Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./Home.css";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import { fetchBannersStart } from "../../store/actions/LookUpAction";
import { t, withTranslation } from "react-multi-lang";

function ThumbnailPlugin(mainRef) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(next);
      });
    });
  };
}

function HomeBannerNew(props) {
  //   const [sliderRef, instanceRef] = useKeenSlider({
  //     initial: 0,
  //   })

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    props.dispatch(fetchBannersStart());
  }, []);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <>
      {!props.banners.loading ?
        <div className="home-main-slider-sec">
          <div ref={sliderRef} className="keen-slider kenn-slider-preview-img">
            {props.banners.data.banner_images &&
              props.banners.data.banner_images.map((bannerImage, i) =>
                <div className={`keen-slider__slide number-slide${i}`}>
                  <img
                    className="home-banner-slider-img"
                    src={bannerImage.picture}
                    alt=""
                  />
                  <div className="kenn-slider-preview-info">
                    <h5>{bannerImage.title}</h5>
                    <h1>{bannerImage.description}</h1>
                    <p>{bannerImage.content}</p>
                    <Link
                      to={{
                        pathname: `/browse/${bannerImage.category_unique_id}`,
                        type: "category",
                      }}
                      className="btn-request"
                    >
                     {t("request_now")}
                    </Link>
                  </div>
                </div>
              )}
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={ instanceRef.current.track.details && (
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  )
                }
              />
            </>
          )}

          <div
            ref={thumbnailRef}
            className="keen-slider thumbnail kenn-slider-thumbnail-img scrollx"
          >
            {props.banners.data.banner_images &&
              props.banners.data.banner_images.map((bannerImage, i) =>
                <div className={`keen-slider__slide number-slide${i}`}>
                  <div className="home-slider-thumbinals-card">
                    <img
                      className="home-slider-thumbnail-img"
                      src={bannerImage.thumbnail}
                      alt=""
                    />
                    <div className="home-slider-thumbnail-info">
                      <h6>{bannerImage.title}</h6>
                      <h4>{bannerImage.description}</h4>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div> : null}
    </>
  );
}

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

const mapStateToPros = (state) => ({
  banners: state.lookup.banners,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(HomeBannerNew));
