import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
 Container,
 Row,
 Col,
 Image, 
 Media,
} from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import AllPastEventsCard from "./AllPastEventsCard";
import AllPastEventsSlider from "./AllPastEventsSlider";
import AllUpCommingEventsCard from "./AllUpCommingEventsCard";
import AllUpCommingEventsSlider from "./AllUpCommingEventsSlider";

const EventsIndex = (props) => {

    return (
    <>
        <div className="events-wrapper-sec">
            <div className="events-sidebar-sec">
                <ul className="events-list-sec list-unstyled">
                    <Media as="li">
                        <Link to="#" className="active">
                            <Image
                                src="assets/images/search-white-icon.svg"
                                alt="user-image"
                                className="search-siderbar-icon"
                            /> 
                            Explore events
                        </Link>
                    </Media>
                    <Media as="li">
                        <Link to="#">
                            <Image
                                src="assets/images/live-tickets-icon.svg"
                                alt="user-image"
                                className="search-siderbar-icon"
                            /> 
                            My tickets
                        </Link>
                    </Media>
                </ul>
            </div>
            <div className="events-right-main-content">
                <div className="live-events-header-sec">
                    <h2>Discover live events</h2>
                    <div className="live-event-looking-sec">
                        <div>
                            <Image
                                src="assets/images/mobile-icon-green.svg"
                                alt="icons"
                                className="live-events-mobile-icon"
                            /> 
                        </div>
                        <div className="resp-flex-content">
                            <span>Looking for Phoneo Calls instead?</span>
                            <Link to="#">
                                See upcoming Phoneo Calls
                            </Link>
                        </div>
                    </div>
                </div>
                <AllPastEventsCard/>
                <AllPastEventsSlider/>
                <AllUpCommingEventsCard/>
                <AllUpCommingEventsSlider/>
            </div>
        </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(EventsIndex);
