import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../Home/Home.css";
import StarRatings from "react-star-ratings";
import HomeFeatured from "../Home/HomeFeatured";
import ModelSuggestionSec from "../Home/ModelSuggestionSec";
import HowItWorksModel from "./HowItWorksModel";
import {
  fetchModelPostsStart,
  fetchModelRatingStart,
  fetchModelSubStart,
  fetchModelSuggestionStart,
  fetchSingleModelStart,
} from "../../store/actions/ModelAction";
import {
  followUserStart,
  unFollowUserStart,
} from "../../store/actions/FollowAction";
import AllReviewModal from "./AllReviewModal";
import ImageLoader from "../Helper/ImageLoader";
import ModelSubscriptionSec from "./ModelSubscriptionSec";
import ModelVideoSec from "./ModelVideoSec";
import ModelStaticSec from "./ModelStaticSec";
import ModelProfileLoader from "../Handlers/Loaders/ModelProfileLoader";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { t, withTranslation } from "react-multi-lang";

const ModelProfileIndex = (props) => {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevParams = usePrevious(props.match.params.username);

  useEffect(() => {
    if (prevParams !== props.match.params.username) {
      console.log("true");
      props.dispatch(
        fetchSingleModelStart({
          username: props.match.params.username,
        })
      );
      props.dispatch(
        fetchModelPostsStart({ model_username: props.match.params.username })
      );

      props.dispatch(
        fetchModelSubStart({ model_username: props.match.params.username })
      );

      props.dispatch(
        fetchModelSuggestionStart({
          model_username: props.match.params.username,
        })
      );
    }
  }, [prevParams, props.match.params.username]);

  const [show, setShow] = useState(false);

  const [howItWorksModal, setHowItWorksModal] = useState(false);

  const handleCloseHowItWorksModal = () => {
    setHowItWorksModal(false);
  };

  const [allReviewModal, setAllReviewModal] = useState(false);

  const handleCloseAllReviewModal = () => {
    setAllReviewModal(false);
  };

  const joinFanClub = () => {
    props.dispatch(
      followUserStart({ user_id: props.singleModel.data.user.user_id })
    );
    props.dispatch(
      fetchSingleModelStart({
        username: props.match.params.username,
      })
    );
  };

  const exitFanClub = () => {
    props.dispatch(
      unFollowUserStart({ user_id: props.singleModel.data.user.user_id })
    );

    props.dispatch(
      fetchSingleModelStart({
        username: props.match.params.username,
      })
    );
  };

  const showRating = (event) => {
    event.preventDefault();

    // if (props.modelRating.loading)
    props.dispatch(
      fetchModelRatingStart({
        model_username: props.match.params.username,
      })
    );

    setAllReviewModal(true);
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Profile Link Copied!!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {props.singleModel.loading ? (
        <ModelProfileLoader />
      ) : (
        <>
          <div className="single-filter-view-sec">
            <div className="single-filter-view-header-sec">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{t("home")}</Breadcrumb.Item>
                <Breadcrumb.Item
                  href={
                    "/browse/" + props.singleModel.data.categories.category
                      ? props.singleModel.data.categories.category_unique_id
                      : ""
                  }
                >
                  {props.singleModel.data.user.category_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {props.singleModel.data.user.subcategory_name}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="btn-action-sec">
                {props.singleModel.data.user.user_id !=
                  localStorage.getItem("userId") &&
                  ""
                  // <Button
                  //   className="btn-join"
                  //   onClick={
                  //     props.singleModel.data.follow_status
                  //       ? exitFanClub
                  //       : joinFanClub
                  //   }
                  // >
                  //   {props.singleModel.data.follow_status
                  //     ? "Exit Fan Club"
                  //     : "Join Fan Club"}
                  // </Button>
                }

                <Button className="btn-copy">
                  <CopyToClipboard text={window.location.href} onCopy={onCopy}>
                    <Image
                      src="assets/images/copy.svg"
                      alt="user-image"
                      className="icons-img"
                    />
                  </CopyToClipboard>
                </Button>
              </div>
            </div>
            <div className="single-filter-view-body-sec">
              <div className="single-filter-profile-sec">
                <div className="profile-wrapper">
                  <div className="profile-img-sec">
                    <ImageLoader
                      image={props.singleModel.data.user.picture}
                      className="filter-profile-img"
                    />
                  </div>
                </div>
                <div className="profile-details-sec">
                  <h2 className="title">{props.singleModel.data.user.name}</h2>
                  <p className="sub-desc">
                    {props.singleModel.data.user.category_name}
                  </p>
                  <p className="sub-desc">
                    {props.singleModel.data.user.subcategory_name}
                  </p>
                  {props.singleModel.data.user.is_featured ? (
                    <Badge className="feeding-badge">
                      <Image
                        src={
                          window.location.origin + "/assets/images/featured.svg"
                        }
                        alt={props.singleModel.data.user.name}
                        className="flag-icon-img text-uppercase"
                      />
                      {t("featured")}
                    </Badge>
                  ) : null}
                  <h4 className="desc">{props.singleModel.data.user.about}</h4>
                </div>
              </div>
              {props.singleModel.data.user.total_ratings > 0 ? (
                <div className="single-filter-review-sec">
                  <div className="rating-left-sec">
                    <p>
                      <StarRatings
                        rating={props.singleModel.data.user.overall_rating}
                        starRatedColor="rgb(255, 214, 67)"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="0px"
                      />
                    </p>
                    <Link to="#" className="see-all-link" onClick={showRating}>
                      {t("see_all")} {props.singleModel.data.user.total_ratings}{" "}
                      {t("reviews")}
                    </Link>
                  </div>
                  {props.singleModel.loading ? (
                    "Loading..."
                  ) : (
                    <div className="review-info-sec">
                      <h4 className="title">
                        Reviewed by{" "}
                        {props.singleModel.data.model_ratings.username} on{" "}
                        {props.singleModel.data.model_ratings.updated_formatted}
                      </h4>
                      <p className="desc">
                        <span id="display_1">
                          {props.singleModel.data.model_ratings.review}
                        </span>
                        {/* {show && (
                          <span id="display_1">
                            {props.singleModel.data.model_ratings.review}
                          </span>
                        )}
                        <Link
                          to="#"
                          className="more-link"
                          onClick={() => setShow(!show)}
                        >
                          {" "}
                          more
                        </Link> */}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              <ModelSubscriptionSec
                subscription={props.modelSub}
                modelDetails={props.singleModel}
              />
              <div className="request-btn-sec">
                <Link to="#">
                  <Button
                    className="btn-how-it-works"
                    onClick={() => setHowItWorksModal(true)}
                  >
                    <Image
                      src="assets/images/info-icon.svg"
                      alt="user-image"
                      className="info-icon"
                    />{" "}
                    {t("how_does_it_work")}
                  </Button>
                </Link>
              </div>
              <div className="video-post-sec">
                <Row>
                  {props.modelPost.loading
                    ? "Loading..."
                    : props.modelPost.data.posts.length > 0
                    ? props.modelPost.data.posts.map((post) => (
                        <ModelVideoSec post={post} />
                      ))
                    : ""}
                </Row>
              </div>
              <ModelStaticSec />
              <div className="single-filter-footer-sec">
                <ModelSuggestionSec models={props.modelSuggestion} />
              </div>
            </div>
          </div>
        </>
      )}
      <HowItWorksModel
        howItWorksModal={howItWorksModal}
        setHowItWorksModal={setHowItWorksModal}
        handleCloseHowItWorksModal={handleCloseHowItWorksModal}
      />
      <AllReviewModal
        allReviewModal={allReviewModal}
        setAllReviewModal={setAllReviewModal}
        handleCloseAllReviewModal={handleCloseAllReviewModal}
        modelRating={props.modelRating}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  modelPost: state.model.modelPost,
  modelSub: state.model.modelSub,
  modelRating: state.model.modelRating,
  modelSuggestion: state.model.modelSuggestion,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ModelProfileIndex));
