import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Payment.css";
import { Container, Row, Col, Button, Form, Badge, InputGroup, FormControl } from "react-bootstrap";
import configuration from "react-global-configuration";

const PaymentCard = (props) => {
    useEffect(() => { }, []);
    return (
        <>
            <div className="payment-card-sec">
                <h3 className="title">Payment info</h3>
                <p className="desc">Your card isn’t charged until the video is complete</p>
                <div className="payment-summary-sec">
                    <h3 className="summary-title">Summary</h3>
                    <div className="summary-divider"></div>
                    <div className="summary-body-sec">
                        <div className="summary-row">
                            <div>
                                <Button className="btn-dummy">
                                    <div className="dummy-input">
                                    </div>
                                    <span>Add promo code</span>
                                </Button>
                            </div>
                        </div>
                        <div className="summary-total-sec">
                            <h5 className="pb-0">Subtotal <strong>{configuration.get("configData.currency")}105</strong></h5>
                            <h5>Service fee <strong>{configuration.get("configData.currency")}5.25</strong></h5>
                        </div>
                        <h3>Total <span>{configuration.get("configData.currency")}110.25</span></h3>
                    </div>
                </div>
                <div className="card-choose-type-sec">
                    <Form className="radio-group-wrapper">
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                id="card"
                                label="Credit/Debit Card"
                                custom
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                id="paypal"
                                label="PayPal"
                                custom
                            />
                            <Form.Text className="note-pay">
                                You will be redirected to the paypal site.
                            </Form.Text>
                        </Form.Group>
                        <div className="card-form">
                            <InputGroup className="input-right-border mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon2">
                                        <i class="far fa-credit-card card-icon"></i>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Card Number"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                            </InputGroup>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="MM" />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="YY" />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="CVV" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button className="btn-continue btn-block">
                                Book for {configuration.get("configData.currency")}110.25 USD
                            </Button>
                            <small className="payment-note">If, for some reason, your request is not fulfilled, 
                                the hold on your payment card will be removed (usually in 5-7 business days)
                            </small>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PaymentCard);
