import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./Account.css";
import { deleteAccountStart } from "../../store/actions/UserAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye , faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { t, withTranslation } from "react-multi-lang";


const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const DeleteAccount = (props) => {

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(deleteAccountStart({ password: password }));
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="change-password-sec">
          <div className="change-password-card">
            <h4 className="title">{t("delete_note")}</h4>
            <p className="desc">
             {t("delete_sub_note")}
            </p>
            <Form className="change-password-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("passwords")}</Form.Label>
                <Form.Control
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter your password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
                  <i
                        className="delete-toggle-password"
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown?eyeSlash:eye}
                      </i>{" "}
              </Form.Group>
              <Button
                className="btn-change-password"
                disabled={props.deleteAcc.buttonDisable}
                type="submit"
              >
                {props.deleteAcc.loadingButtonContent !== null
                  ? props.deleteAcc.loadingButtonContent
                  : "Delete Account"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(DeleteAccount));
