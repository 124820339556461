import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Subscription.css";
import { Form, Button, Image } from "react-bootstrap";
import {
  subscriptionCreateStart,
  subscriptionViewStart,
} from "../../store/actions/SubscriptionAction";
import { t, withTranslation } from "react-multi-lang";

const EditSubscription = (props) => {
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    props.dispatch(
      subscriptionViewStart({
        user_subscription_unique_id:
          props.match.params.user_subscription_unique_id,
      })
    );

    if (props.subView.loading) {
    } else {
      setInputData(props.subView.data.user_subscription);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(subscriptionCreateStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="create-subscription-sec">
          <div className="create-subscription-card">
            <h3 className="title">Edit Subscription</h3>
            <Form className="create-subscription-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Title*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mathew Perry"
                  value={inputData.title}
                  name="title"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      title: event.currentTarget.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Duration*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Duration in mins"
                  value={inputData.plan}
                  name="plan"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      plan: event.currentTarget.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t("price")}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Price"
                  value={inputData.amount}
                  name="amount"
                  onChange={(event) => {
                    setInputData({
                      ...inputData,
                      amount: event.currentTarget.value,
                    });
                  }}
                />
              </Form.Group>
              <Button
                className="btn-continue btn-block"
                disabled={props.subCreate.buttonDisable}
                type="submit"
              >
                {props.subCreate.loadingButtonContent != null
                  ? props.subCreate.loadingButtonContent
                  : "Submit"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subCreate: state.subscriptions.subCreate,
  subView: state.subscriptions.subView,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)((withTranslation(EditSubscription)));
