import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t, withTranslation } from "react-multi-lang";
import ReactCodeInput from "react-verification-code-input";

import { Link } from "react-router-dom";
import "./Auth.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  registerVerifyResendStart,
  registerVerifyStart,
} from "../../store/actions/UserAction";

const RegisterVerification = (props) => {
  useEffect(() => {}, []);

  const [verificationCode, setVerificationCode] = useState("");

  const handleChange = (input) => {
    setVerificationCode(input);
  };
  const submitVerfication = (event) => {
    event.preventDefault();
    props.dispatch(
      registerVerifyStart({ verification_code: verificationCode })
    );
  };

  const resendVerfication = (event) => {
    event.preventDefault();
    props.dispatch(registerVerifyResendStart());
  };
  return (
    <>
      <div className="main-wrapper register-verify-sec">
        <Container>
          <Row>
            <Col md={6} className="offset-3">
              <div className="register-verify-card">
                <p className="desc">
                  {t("register-verify-desc")}
                </p>
                <h2 className="title-head">{t("register-verify-heading")}</h2>
                <Form onSubmit={submitVerfication}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <ReactCodeInput
                      fields={6}
                      onChange={handleChange}
                      autoFocus={true}
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    className="btn-continue"
                    // disabled={props.registerVerify.buttonDisable}
                    disabled={!verificationCode}
                  >
                    {props.registerVerify.loadingButtonContent != null
                      ? props.registerVerify.loadingButtonContent
                      : "Verify Now"}
                  </Button>
                </Form>
                <h6 className="sub-desc">
                 {t("register-verify-sub-desc")}
                  <span className="ml-2">
                    <Link to="#" onClick={resendVerfication}>
                      {t("send_again")}
                    </Link>
                  </span>
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  registerVerify: state.users.registerVerify,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(RegisterVerification));
