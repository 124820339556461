import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
  Form,
  FormGroup,
} from "react-bootstrap";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { t, withTranslation } from "react-multi-lang";

const SingleModelNew = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 1.5,
      },
      1000: {
        items: 1.5,
      },
    },
  };

  return (
    <>
      <div className="single-model-new-sec">
        <Container>
          <div className="single-model-new-header-sec">
            <Breadcrumb>
              <Breadcrumb.Item href="#">{t("home")}</Breadcrumb.Item>
              <Breadcrumb.Item>{t("musician")}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t("lead_singers")}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="btn-action-sec">
              <Button className="btn-join">{t("follow")}</Button>
              <Button className="btn-copy">
                <Image
                  src={window.location.origin + "/assets/images/copy.svg"}
                  alt="user-image"
                  className="icons-img"
                />
              </Button>
            </div>
          </div>
          <div className="single-model-new-body-sec">
            <Row>
              <Col md={6}>
                <div className="single-model-new-video-slider-sec">
                  <OwlCarousel className="owl-theme" loop {...options} nav>
                    <div className="single-model-new-video-card">
                      <div className="single-model-new-video-img-sec">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/single-model-video-img.jpg"
                          }
                          alt="user-image"
                          className="single-model-new-video-img"
                        />
                      </div>
                    </div>
                    <div className="single-model-new-video-card">
                      <div className="single-model-new-video-img-sec">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/single-model-video-img-1.jpg"
                          }
                          alt="user-image"
                          className="single-model-new-video-img"
                        />
                      </div>
                    </div>
                    <div className="single-model-new-video-card">
                      <div className="single-model-new-video-img-sec">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/single-model-video-img-2.jpg"
                          }
                          alt="user-image"
                          className="single-model-new-video-img"
                        />
                      </div>
                    </div>
                    <div className="single-model-new-video-card">
                      <div className="single-model-new-video-img-sec">
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/single-model-video-img-3.jpg"
                          }
                          alt="user-image"
                          className="single-model-new-video-img"
                        />
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </Col>
              <Col md={6}>
                <div className="single-model-new-info-sec">
                  <div className="single-model-new-info-header">
                    <div className="rating-star-model-sec">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="#FFF400"
                        stroke="#FFF400"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-testid="icon-star"
                      >
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                      </svg>
                      <span>5.0</span>
                    </div>
                    <div className="all-review-count-sec">
                      <Link to="#">13 {t("reviews")}</Link>
                    </div>
                    <span className="dot-theme">•</span>
                    <div className="follower-count">61</div>
                    <div className="follower-text">{t("followers")}</div>
                  </div>
                  <div className="single-model-bio-sec">
                    <div className="single-model-bio-info">
                      <h2>{"tori_v"}</h2>
                      <p>{t("youtube_star")}</p>
                    </div>
                    <div className="single-model-user-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/single-model-profile-img.jpg"
                        }
                        alt="user-image"
                        className="single-model-profile-img"
                      />
                    </div>
                  </div>
                  <div className="delivery-available-status">
                    <Image
                      src={
                        window.location.origin +
                        "/assets/images/delivery-status.svg"
                      }
                      alt="user-image"
                      className="delivery-available-status-icon"
                    />
                    <p>{t("delivery_available")}</p>
                  </div>
                  <div className="single-profile-new-model-details">
                    {t("artist-tikToker-youtuber-tori_v")}
                  </div>
                  <div className="single-profile-new-model-radio-group">
                    <h4>{t("choose_an_option")}</h4>
                    <Form className="single-model-choose-option-sec">
                      <Form.Group>
                        <label className="card-choose-option">
                          <input name="plan" className="radio" type="radio" />
                          <div className="card-option-box">
                            <div className="card-option-left-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/users-white.svg"
                                }
                                alt="user-image"
                                className="users-icon"
                              />
                              <div className="card-option-left-info">
                                <h5>{t("personal_use")}</h5>
                                <p>
                                  {t(
                                    "personalized_video_for_you_or_someone_else"
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="card-option-right-sec">
                              <h6>₹15,064</h6>
                            </div>
                          </div>
                        </label>
                      </Form.Group>
                      <Form.Group>
                        <label className="card-choose-option">
                          <input name="plan" className="radio" type="radio" />
                          <div className="card-option-box">
                            <div className="card-option-left-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/users-white.svg"
                                }
                                alt="user-image"
                                className="users-icon"
                              />
                              <div className="card-option-left-info">
                                <h5>{t("for_business")}</h5>
                                <p>{t("engg_video_emp")}</p>
                              </div>
                            </div>
                            <div className="card-option-right-sec">
                              <h6>₹105,448</h6>
                            </div>
                          </div>
                        </label>
                      </Form.Group>
                      <FormGroup>
                        <Button
                          type="submit"
                          className="btn-edit-profile btn btn-primary"
                        >
                          Book now ₹15,064
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="new-review-sec">
            <Row>
              <Col md={12}>
                <div className="new-review-header-sec">
                  <h3>{t("reviews")}</h3>
                  <Link to="#">
                    <p>
                      {t("see_all")} 150 {t("reviews")}
                    </p>
                  </Link>
                </div>
                <div className="new-review-box">
                  <div className="new-review-card">
                    <div className="new-review-info">
                      <div className="new-review-img-sec">
                        <span>R</span>
                      </div>
                      <div className="new-review-details">
                        <h6>Ryan</h6>
                        <p>{t("about")} 2 months ago</p>
                      </div>
                    </div>
                    <div className="new-review-rating-content-sec">
                      <div className="new-review-rating-card">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                      </div>
                      <div className="new-review-desc-content">
                        <p>{t("review_desc")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="new-review-card">
                    <div className="new-review-info">
                      <div className="new-review-img-sec">
                        <span>R</span>
                      </div>
                      <div className="new-review-details">
                        <h6>Ryan</h6>
                        <p>{t("about")} 2 months ago</p>
                      </div>
                    </div>
                    <div className="new-review-rating-content-sec">
                      <div className="new-review-rating-card">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#FFF400"
                          stroke="#FFF400"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-testid="icon-star"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                      </div>
                      <div className="new-review-desc-content">
                        <p>{t("review_desc")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="related-categories-sec">
        <Container>
          <Row>
            <Col md={12}>
              <h3>{t("related_categories")}</h3>
              <div className="related-categories-box">
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
                <Link to="#">
                  <div className="related-categories-card">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#adaeb5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="Styled__TagSearchIcon-sc-12o91u9-4 ghnhpY"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <span>{t("atheletes")}</span>
                    </p>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="see-all-link-sec">
                <Link to="#" className="see-all-link">
                  {t("see_all")}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={12} lg={6} xl={6}>
            <div className="single-filter-footer-sec new-single-model-footer-sec">
              <div className="single-filter-about-sec">
                <h4 className="title">
                  {t("what_is")} {configuration.get("configData.site_name")}?
                </h4>
                <Row>
                  <Col md={4} className="resp-mrg-btm-xs">
                    <div className="about-item">
                      <div className="icon-sec">
                        <Image
                          src="assets/images/mobile-icon.svg"
                          alt="user-image"
                          className="about-icons"
                        />
                      </div>
                      <h4 className="desc">{t("send_your_request")}</h4>
                    </div>
                  </Col>
                  <Col md={4} className="resp-mrg-btm-xs">
                    <div className="about-item">
                      <div className="icon-sec">
                        <Image
                          src="assets/images/video-icon.svg"
                          alt="user-image"
                          className="about-icons"
                        />
                      </div>
                      <h4 className="desc">{t("get_your_video")}</h4>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="about-item">
                      <div className="icon-sec">
                        <Image
                          src="assets/images/thumsup.svg"
                          alt="user-image"
                          className="about-icons"
                        />
                      </div>
                      <h4 className="desc">{t("make_their_year")}</h4>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={12} lg={6} xl={6}>
            <div className="single-filter-footer-sec new-single-model-footer-sec padding-zero">
              <div className="single-filter-about-sec">
                <h4 className="title-1">
                  {t("what_does_a_good_request_look_like")}
                </h4>
                <h5 className="sub-title">{t("tip_1")}</h5>
                <p className="desc">{t("tip_1_content")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withTranslation(SingleModelNew);
