import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import "./UploadDocument.css";
import {
  addKycDocumentStart,
  getKycDocumentStart,
} from "../../../store/actions/KycDocumentAction";
import KYCLoader from "../../Handlers/Loaders/KYCLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { t, withTranslation } from "react-multi-lang";

const UploadDocumentIndex = (props) => {
  useEffect(() => {
    props.dispatch(getKycDocumentStart());
  }, []);

  const [inputData, setInputData] = useState({});

  const [image, setImage] = useState({});

  const [uploadDocumentID, setUploadDocumentID] = useState(null);

  const handleChangeImage = (event, doc) => {
    if (event.currentTarget.type === "file") {
      setInputData({
        ...inputData,
        document_file: event.currentTarget.files[0],
        document_id: doc.document_id,
      });
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      reader.onloadend = () => {
        setImage({ ...image, [doc.document_id]: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = (event, doc) => {
    event.preventDefault();
    setUploadDocumentID(doc.document_id);
    props.dispatch(addKycDocumentStart(inputData));
  };
  return (
    <>
      <div className="upload-doc-sec main-wrapper">
        <Container>
          {props.kycDocDetails.loading ? (
            <KYCLoader />
          ) : props.kycDocDetails.data.documents.length > 0 ? (
            props.kycDocDetails.data.documents.map((doc) => (
              <Form className="form-box">
                <div className="title-wrap">
                  <h3 className="title">
                    <i class="fas fa-images mr-2"></i>
                    {doc.name}
                  </h3>
                  <p className="desc">{doc.description}</p>
                </div>
                <Row>
                  <Col lg={6}>
                    <div className="upload-doc-card">
                      <div className="upload-doc-box">
                        <Image
                          src={
                            image[doc.document_id] !== undefined
                              ? image[doc.document_id]
                              : doc.user_document.document_file !== undefined
                              ? doc.user_document.document_file
                              : window.location.origin +
                                "/assets/images/document-upload.svg"
                          }
                          alt="no image"
                          className="doc-upload-img"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-content contact-form-action">
                      <Row>
                        <Col lg={12}>
                          <div className="file-upload-wrap">
                            <input
                              type="file"
                              name={doc.document_id}
                              className="multi file-upload-input with-preview"
                              accept="image/*"
                              onChange={(event) =>
                                handleChangeImage(event, doc)
                              }
                              disabled={
                                props.kycDocDetails.data.is_document_verified === 2 ? true : false
                              }
                            />
                            <span className="file-upload-text">
                              <i className="fa fa-upload mr-2"></i>
                              {t("click_image_here")}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div className="upload-doc-btm-sec">
                  { props.kycDocDetails.data.is_document_verified === 2 ? <span className="theme-btn">{t("verified")}</span>:
                  <Button
                    className="theme-btn"
                    type="submit"
                    onClick={(event) => handleSubmit(event, doc)}
                    disabled={
                      uploadDocumentID === doc.document_id ? true : false
                    }
                  >
                    {uploadDocumentID === doc.document_id
                      ? props.addKycDocInput.loadingButtonContent
                      : "Send for Approval"}
                  </Button>
                  }
                </div>
              </Form>
            ))
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  kycDocDetails: state.kycDocument.kycDocDetails,
  addKycDocInput: state.kycDocument.addKycDocInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(UploadDocumentIndex));
