import React, { useState, useEffect, Component, useRef } from "react";
import { connect } from "react-redux";
import "./FilterView.css";
import { Button, Image } from "react-bootstrap";
import FilterViewHeader from "./FilterViewHeader";
import FilterSec from "./FilterSec";
import ModelListSec from "./ModelListSec";
import {
  fetchCategoryStart,
  fetchSubCategoryStart,
} from "../../store/actions/LookUpAction";
import {
  featuredModelsStart,
  fetchModelDetailsStart,
  modelFiltersStart,
} from "../../store/actions/ModelAction";
import HomeCategory from "../Home/HomeCategory";
import { useParams } from "react-router";

const $ = window.$;

const FilterViewIndex = (props) => {
  const { category_unique_id, sub_category_unique_id } = useParams();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (category_unique_id === "featured") {
      if (props.category.loading) props.dispatch(fetchCategoryStart());
    } else if (category_unique_id === "trending") {
      if (props.category.loading) props.dispatch(fetchCategoryStart());
      props.dispatch(
        fetchModelDetailsStart({
          sort_by: "trending",
        })
      );
    } else {
      if (sub_category_unique_id) {
        props.dispatch(
          fetchModelDetailsStart({
            sub_category_unique_id: sub_category_unique_id,
          })
        );
      } else {
        props.dispatch(
          fetchSubCategoryStart({
            category_unique_id: category_unique_id,
          })
        );
      }
      // else
      //   props.dispatch(
      //     fetchModelDetailsStart({
      //       category_unique_id: category_unique_id,
      //     })
      //   );
    }
  }, []);

  useEffect(() => {
    if (category_unique_id === "featured") {
      props.dispatch(modelFiltersStart({ sort_by: "featured" }));
    } else if (category_unique_id === "trending") {
      props.dispatch(
        fetchModelDetailsStart({
          sort_by: "trending",
        })
      );
    } else {
      if (sub_category_unique_id)
        props.dispatch(
          fetchModelDetailsStart({
            sub_category_unique_id: sub_category_unique_id,
          })
        );
      else
        props.dispatch(
          fetchModelDetailsStart({
            category_unique_id: category_unique_id,
          })
        );
    }
  }, [category_unique_id, sub_category_unique_id]);

  const [isFilterToggle, setIsFilterToggle] = React.useState(true);

  const [filterInputData, setFilterInputData] = useState({});

  const filterOption = ({ currentTarget: input }) => {
    props.dispatch(
      modelFiltersStart({ ...filterInputData, [input.name]: input.value })
    );
    setFilterInputData({ ...filterInputData, [input.name]: input.value });
  };

  const clearFilter = (event) => {
    event.preventDefault();
    props.dispatch(fetchModelDetailsStart(props.model.inputData));
    $(".custom-control-input").prop("checked", false);
    setFilterInputData({});
  };

  return (
    <>
      <div className="main-wrapper">
        {category_unique_id === "featured" ||
        category_unique_id === "trending" ? (
          <HomeCategory category={props.category} type="category" />
        ) : (
          <HomeCategory category={props.subCat} type="subcategory" />
        )}

        <FilterViewHeader
          isFilterToggle={isFilterToggle}
          setIsFilterToggle={setIsFilterToggle}
          filterInputData={filterInputData}
          setFilterInputData={setFilterInputData}
          filterOption={filterOption}
          clearFilter={clearFilter}
        />

        <div className="filter-display-sec">
          {isFilterToggle && (
            <div className="left-sec mobile-hide">
              <FilterSec
                filterInputData={filterInputData}
                setFilterInputData={setFilterInputData}
                filterOption={filterOption}
              />
            </div>
          )}

          <div className="right-sec">
            {category_unique_id === "featured" ? (
              <ModelListSec model={props.model} heading={category_unique_id} />
            ) : (
              <ModelListSec
                model={props.model}
                heading={
                  sub_category_unique_id
                    ? sub_category_unique_id
                    : category_unique_id
                }
              />
            )}
            <div className="pagination-sec hidden">
              <Button className="btn-prev">
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/rounded-arrow-grey.svg"
                  }
                  alt="arrow-image"
                  className="btn-prev-icon"
                />
              </Button>
              <span>Page 1 of 1</span>
              <Button className="btn-next">
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/rounded-arrow-grey.svg"
                  }
                  alt="arrow-image"
                  className="btn-next-icon"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  subCat: state.lookup.subCat,
  model: state.model.model,
  category: state.lookup.category,
  featuredModel: state.model.featuredModel,
  modelFilters: state.model.modelFilters,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FilterViewIndex);
