import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Image,
    Media,
} from "react-bootstrap";
import { connect } from "react-redux";
import Slider from "react-slick";
import configuration from "react-global-configuration";

const AllUpCommingEventsSlider = (props) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        adaptiveHeight: true,
        slidesToShow: 4,
    
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
    
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };


    return (
        <>
            <div className="all-upcoming-events-header-sec">
                <h4>All Upcoming events</h4>
            </div>
            <Slider {...settings} className="all-upcoming-events-slider">
                <div className="all-upcoming-events-items">
                    <div className="all-upcoming-events-card">
                        <div className="all-upcoming-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-1.jpg"
                                  }
                                alt="live-events-img"
                                className="all-upcoming-events-img"
                            />
                            <div className="all-upcoming-ticket-price-card">
                                <div className="my-ticket-count">
                                <Image
                                    src="assets/images/live-tickets-icon.svg"
                                    alt="user-image"
                                    className="my-ticket-icon"
                                /> <span>4</span>
                                </div>
                                <div className="my-price-amount-count">
                                    {configuration.get("configData.currency")}0.00
                                </div>
                            </div>
                        </div>
                        <div className="all-upcoming-events-info">
                            <div className="all-upcoming-events-status">
                                Event Upcoming
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        </div>
                    </div>
                </div>
                <div className="all-upcoming-events-items">
                    <div className="all-upcoming-events-card">
                        <div className="all-upcoming-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-2.jpg"
                                  }
                                alt="live-events-img"
                                className="all-upcoming-events-img"
                            />
                            <div className="all-upcoming-ticket-price-card">
                                <div className="my-ticket-count">
                                <Image
                                    src="assets/images/live-tickets-icon.svg"
                                    alt="user-image"
                                    className="my-ticket-icon"
                                /> <span>4</span>
                                </div>
                                <div className="my-price-amount-count">
                                    {configuration.get("configData.currency")}0.00
                                </div>
                            </div>
                        </div>
                        <div className="all-upcoming-events-info">
                            <div className="all-upcoming-events-status">
                                Event Upcoming
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        </div>
                    </div>
                </div>
                <div className="all-upcoming-events-items">
                    <div className="all-upcoming-events-card">
                        <div className="all-upcoming-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-3.jpg"
                                  }
                                alt="live-events-img"
                                className="all-upcoming-events-img"
                            />
                            <div className="all-upcoming-ticket-price-card">
                                <div className="my-ticket-count">
                                <Image
                                    src="assets/images/live-tickets-icon.svg"
                                    alt="user-image"
                                    className="my-ticket-icon"
                                /> <span>4</span>
                                </div>
                                <div className="my-price-amount-count">
                                    {configuration.get("configData.currency")}0.00
                                </div>
                            </div>
                        </div>
                        <div className="all-upcoming-events-info">
                            <div className="all-upcoming-events-status">
                                Event Upcoming
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        </div>
                    </div>
                </div>
                <div className="all-upcoming-events-items">
                    <div className="all-upcoming-events-card">
                        <div className="all-upcoming-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-4.jpg"
                                  }
                                alt="live-events-img"
                                className="all-upcoming-events-img"
                            />
                            <div className="all-upcoming-ticket-price-card">
                                <div className="my-ticket-count">
                                <Image
                                    src="assets/images/live-tickets-icon.svg"
                                    alt="user-image"
                                    className="my-ticket-icon"
                                /> <span>4</span>
                                </div>
                                <div className="my-price-amount-count">
                                    {configuration.get("configData.currency")}0.00
                                </div>
                            </div>
                        </div>
                        <div className="all-upcoming-events-info">
                            <div className="all-upcoming-events-status">
                                Event Upcoming
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        </div>
                    </div>
                </div>
                <div className="all-upcoming-events-items">
                    <div className="all-upcoming-events-card">
                        <div className="all-upcoming-events-img-sec">
                            <Image
                                 src={
                                    window.location.origin + "/assets/images/live-events/live-events-5.jpg"
                                  }
                                alt="live-events-img"
                                className="all-upcoming-events-img"
                            />
                            <div className="all-upcoming-ticket-price-card">
                                <div className="my-ticket-count">
                                <Image
                                    src="assets/images/live-tickets-icon.svg"
                                    alt="user-image"
                                    className="my-ticket-icon"
                                /> <span>4</span>
                                </div>
                                <div className="my-price-amount-count">
                                    {configuration.get("configData.currency")}0.00
                                </div>
                            </div>
                        </div>
                        <div className="all-upcoming-events-info">
                            <div className="all-upcoming-events-status">
                                Event Upcoming
                            </div>
                            <h5>25th Anniversary Q+A Panel with Nicholas Brendon</h5>
                        </div>
                    </div>
                </div>
            </Slider>
        </>
    );
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(null, mapDispatchToProps)(AllUpCommingEventsSlider);
