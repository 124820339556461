import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import StaticPage from "../StaticPages/StaticPage";
import CardsIndex from "../Payments/Cards/CardsIndex";
import HomeIndex from "../Home/HomeIndex";
import MainLayout from "../Layouts/MainLayout";
import EditProfile from "../Account/EditProfile";
import DeleteAccount from "../Account/DeleteAccount";
import ChangePassword from "../Account/ChangePassword";
import SettingsIndex from "../Settings/SettingsIndex";
import WalletIndex from "../Payments/Wallets/WalletIndex";
import BillingAccountsIndex from "../Payments/BillingAccounts/BillingAccountsIndex";
import NoDataFound from "../NoDataFound/NoDataFound";
import InboxIndex from "../Chat/InboxIndex";
import UploadDocumentIndex from "../Payments/UploadDocument/UploadDocumentIndex";
import PaymentSuccessIndex from "../Payments/PaymentSuccess/PaymentSuccessIndex";
import PaymentFailureIndex from "../Payments/PaymentFailure/PaymentFailureIndex";
import RegisterVerification from "../Auth/RegisterVerification";
// import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../../components/translation/en.json";
import pt from "../../components/translation/pt.json";
import es from "../../components/translation/es.json";
import gre from "../../components/translation/gre.json";
import LoginIndex from "../Auth/LoginIndex";
import SignupIndex from "../Auth/SignupIndex";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import ResetPassword from "../Auth/ResetPassword";
import FilterViewIndex from "../FilterView/FilterViewIndex";
import SingleFilterView from "../FilterView/SingleFilterView";
import SingleActorView from "../Home/SingleActorView";
import RequestBookingIndex from "../RequestBooking/RequestBookingIndex";
import DashboardIndex from "../Dashboard/DashboardIndex";
import InvitePageIndex from "../InvitePage/InvitePageIndex";
import PaymentIndex from "../Payments/PaymentIndex";
import EnrollIndex from "../Enroll/EnrollIndex";
import TextMe from "../Chat/TextMe";
import AddBillingAccount from "../Payments/BillingAccounts/AddBillingAccount";
import PaymentInvoice from "../Payments/PaymentInvoice";
import ModelProfileIndex from "../ModelProfile/ModelProfileIndex";
import SubscriptionList from "../Subscription/SubscriptionList";
import CreateSubscription from "../Subscription/CreateSubscription";
import SubscriptionView from "../Subscription/SubscriptionView";
import Followers from "../FansFollowing/Followers";
import Following from "../FansFollowing/Following";
import RequestView from "../RequestBooking/RequestView";
import RequestList from "../RequestBooking/RequestList";
import RequestVideoUpload from "../RequestBooking/RequestUploadVideo";
import SingleVideoSecIndex from "../ModelProfile/SingleVideoSec/SingleVideoSecIndex";
import CategoriesIndex from "../Categories/CategoriesIndex";
import Logout from "../Account/Logout";
import EditSubscription from "../Subscription/EditSubscription";
import EnrollSuccess from "../Enroll/EnrollSuccess";
import SetPasswordIndex from "../Auth/SetPasswordIndex";
import SingleModelNew from "../Home/SingleModelNew";
import EventsIndex from "../Events/EventsIndex";
import MyTicketsIndex from "../Events/MyTicketsIndex";
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import EventsRequest from "../Events/EventsRequest";

setTranslations({ pt, en, es, gre });

const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");

      accessToken = localStorage.getItem("accessToken");

      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "gre";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);

      console.log("response", response);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }
    $("#google_analytics").html(
      configuration.get("configData.google_analytics")
    );

    $("#header_scripts").html(configuration.get("configData.header_scripts"));

    $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <link
            rel="apple-touch-icon"
            href={configuration.get("configData.site_icon")}
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>
        </Helmet>
        <Switch>
          <AppRoute
            path={"/"}
            component={HomeIndex}
            exact
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/login"}
            component={LoginIndex}
            layout={MainLayout}
          />
          <AppRoute
            authentication={this.state.authentication}
            path={"/set-password/:userId/:userToken"}
            component={SetPasswordIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/signup"}
            component={SignupIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/forgot-password"}
            component={ForgotPasswordIndex}
            layout={MainLayout}
          />

          <AppRoute
            path={"/reset-password/:token"}
            component={ResetPassword}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/browse/:category_unique_id/:sub_category_unique_id?"}
            component={FilterViewIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/single-filter-view"}
            component={SingleFilterView}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/request-booking/:username/:subscription_id"}
            component={RequestBookingIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={DashboardIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/invite"}
            component={InvitePageIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-invoice"}
            component={PaymentInvoice}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payments"}
            component={PaymentIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/enroll"}
            component={EnrollIndex}
            layout={MainLayout}
          />
          <AppRoute
            authentication={this.state.authentication}
            path={"/enroll-success"}
            component={EnrollSuccess}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/post/:post_unique_id"}
            component={SingleVideoSecIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/text-me"}
            component={TextMe}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-success"}
            component={PaymentSuccessIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-failure"}
            component={PaymentFailureIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-accounts"}
            component={BillingAccountsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/wallet"}
            component={WalletIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePassword}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccount}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/settings"}
            component={SettingsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/kyc-documents"}
            component={UploadDocumentIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/inbox"}
            component={InboxIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/no-data-found"}
            component={NoDataFound}
            layout={MainLayout}
          />

          <AppRoute
            path={"/page/:title"}
            component={StaticPage}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/register/verify"}
            component={RegisterVerification}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-accounts"}
            component={BillingAccountsIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-billing-account"}
            component={AddBillingAccount}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscription-list"}
            component={SubscriptionList}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/create-subscription"}
            component={CreateSubscription}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-subscription/:user_subscription_unique_id"}
            component={EditSubscription}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/subscription-view"}
            component={SubscriptionView}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/followers"}
            component={Followers}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/followings"}
            component={Following}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/requests"}
            component={RequestList}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/request-view/:booking_unique_id"}
            component={RequestView}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/booking/:booking_unique_id"}
            component={RequestVideoUpload}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/categories"}
            component={CategoriesIndex}
            layout={MainLayout}
          />

          <AppRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />

          <AppRoute
            path={"/single-model-new"}
            component={SingleModelNew}
            layout={MainLayout}
          />

          <AppRoute
            path={"/events"}
            component={EventsIndex}
            layout={MainLayout}
          />

          <AppRoute
            path={"/my-tickets"}
            component={MyTicketsIndex}
            layout={MainLayout}
          />

          <AppRoute
            path={"/event-request"}
            component={EventsRequest}
            layout={MainLayout}
          />

          {/* Dont move this route to top */}
          <AppRoute
            authentication={this.state.authentication}
            path={"/:username"}
            component={ModelProfileIndex}
            layout={MainLayout}
          />

          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
