import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Categories.css";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t, withTranslation } from "react-multi-lang";
import {
  fetchCategoryStart,
  fetchSubCategoryStart,
} from "../../store/actions/LookUpAction";
import ImageLoader from "../Helper/ImageLoader";
import { modelCategoryUpdateStart } from "../../store/actions/ModelAction";
import CategoriesLoader from "../Handlers/Loaders/CategoriesLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const CategoriesIndex = (props) => {
  useEffect(() => {
    if (props.category.loading) props.dispatch(fetchCategoryStart());

    setTimeout(() => {
      if (!props.category.loading) {
        props.category.data.categories.forEach(function (category) {
          console.log(category);
          if (category.is_selected == 1) {
            props.dispatch(
              fetchSubCategoryStart({
                category_unique_id: category.category_unique_id,
              })
            );
            setShowSubCategory(true);
          }
        });
      }
    }, 2000);
  }, []);

  const [showSubCategory, setShowSubCategory] = useState(false);

  const [inputData, setInputData] = useState({});

  const fetchSubcategory = (event, category) => {
    props.dispatch(
      fetchSubCategoryStart({ category_unique_id: category.category_unique_id })
    );
    setShowSubCategory(true);
    setInputData({ ...inputData, category_id: event.currentTarget.value });
  };

  const handleSubCategory = ({ currentTarget: input }) => {
    if (input.type === "checkbox") {
      if (input.checked) {
        if (inputData.sub_category_id === undefined) {
          let array = [];
          const value = parseInt(input.value);
          array.push(value);
          setInputData({ ...inputData, sub_category_id: array });
          //   inputData.sub_category_id = array;
          //   this.setState({ formData });
        } else {
          const value = parseInt(input.value);
          let tempVar = inputData.sub_category_id;
          tempVar.push(value);
          setInputData({ ...inputData, sub_category_id: tempVar });
        }
      } else {
        const value = parseInt(input.value);
        let index = inputData.sub_category_id.indexOf(value);
        if (index !== -1) {
          let tempVar = inputData.sub_category_id;
          tempVar.splice(index, 1);
          setInputData({ ...inputData, sub_category_id: tempVar });
        }
      }
    }

    // setInputData({ ...inputData, sub_category_id: input.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(modelCategoryUpdateStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper categories-sec">
        <Container>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col md={12}>
                {props.category.loading ? (
                  <CategoriesLoader />
                ) : props.category.data.categories.length > 0 ? (
                  <>
                    <h3 className="text-white">{t("choose_category")}</h3>
                    <div className="radio-categories-group-sec">
                      {props.category.data.categories.map((category) => (
                        <div
                          type="rounded"
                          className="radio-categories-btn-sec"
                          key={category.category_id}
                        >
                          <input
                            type="radio"
                            id={category.category_id}
                            value={category.category_id}
                            name="category_id"
                            defaultChecked={category.is_selected}
                            onChange={(event) =>
                              fetchSubcategory(event, category)
                            }
                          />
                          <label htmlFor={category.category_id}>
                            <div className="categories-item">
                              <div className="overlay-slider"></div>
                              <ImageLoader
                                image={category.picture}
                                alt="user-image"
                                className="categories-img"
                              />
                              <p className="categories-title">
                                {category.name}
                              </p>
                            </div>
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/checked.svg"
                              }
                              alt="user-image"
                              className="checked-img"
                            />
                          </label>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <NoDataFound />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {showSubCategory &&
                  (props.subCat.loading ? (
                    "Loading..."
                  ) : props.subCat.data.sub_categories.length > 0 ? (
                    <>
                      <h3 className="text-white">{t("choose_sub_category")}</h3>
                      <div className="check-categories-group-sec">
                        {props.subCat.data.sub_categories.map((category) => (
                          <div type="rounded" className="check-btn-sec">
                            <input
                              type="checkbox"
                              id={"category" + category.sub_category_id}
                              value={category.sub_category_id}
                              name={category.sub_category_id}
                              defaultChecked={
                                category.is_selected ? true : false
                              }
                              onChange={handleSubCategory}
                            />
                            <label
                              htmlFor={"category" + category.sub_category_id}
                            >
                              <div className="categories-item">
                                <div className="overlay-slider"></div>
                                <ImageLoader
                                  image={category.picture}
                                  alt="user-image"
                                  className="categories-img"
                                />
                                <p className="categories-title">
                                  {category.name}
                                </p>
                              </div>
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/checked.svg"
                                }
                                alt="user-image"
                                className="checked-img"
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <NoDataFound />
                  ))}
                <Button
                  type="submit"
                  disabled={props.modelCat.buttonDisable}
                  className="btn-continue"
                >
                  {props.modelCat.buttonLoadingContent !== null
                    ? props.modelCat.buttonLoadingContent
                    : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  category: state.lookup.category,
  subCat: state.lookup.subCat,
  modelCat: state.model.modelCat,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(CategoriesIndex));
