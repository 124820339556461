import React, { useState, useEffect } from "react";
import { Modal, Media, Image, Form, Button } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import { postReviewUpdateStart } from "../../store/actions/BookingAction";
import { t, withTranslation } from "react-multi-lang";

const ReviewModal = (props) => {

    const [inputData, setInputData] = useState({});

    useEffect(() => {
        setInputData({
            ...inputData,
            booking_id: props.singleBooking.data.booking.booking_id,
        })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(postReviewUpdateStart(inputData));
    };

    return (
        <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.allReviewModal}
        onHide={props.handleCloseAllReviewModal}
        className="review-modal"
        >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("update_review")}</h4>
            </Modal.Title>
            <i
            className="fas fa-times close-icon"
            onClick={props.handleCloseAllReviewModal}
            ></i>
        </Modal.Header>
        <Modal.Body
            style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}
        >
            <Form onSubmit={handleSubmit} className="">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>{t("ratings")}</Form.Label>
                    <StarRatings
                        rating={inputData.rating}
                        starRatedColor="rgb(255, 214, 67)"
                        numberOfStars={5}
                        changeRating={(rating) =>
                            setInputData({
                            ...inputData,
                            rating: rating,
                            })
                        }
                        starDimension="20px"
                        starSpacing="0px"
                        />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>
                      {/* Review */}
                     {t("comments")}
                      </Form.Label>
                    <Form.Control
                    as="textarea"
                    rows={4}
                    className="height-auto-form"
                    // placeholder="Reviews"
                    placeholder={t("add_your_comments")}
                    name="instructions"
                    onChange={(event) =>
                        setInputData({
                        ...inputData,
                        review: event.currentTarget.value,
                        })
                    }
                    />
                </Form.Group>

            
                <div className="continue-btn-sec">
                    <Button
                    className="btn-continue"
                    type="submit"
                    >
                    {props.postReviews.loadingButtonContent !== null
                        ? props.postReviews.loadingButtonContent
                        : "Submit"}
                    </Button>
                </div>
            </Form>
            
        </Modal.Body>
        </Modal>
    );
};

const mapStateToPros = (state) => ({
    postReviews: state.bookings.postReviews,
});
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(ReviewModal));
  